import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { isDesktop, isHybrid } from '~utils'

export const shouldUseCameraForDocumentCapture = (
  { document_capture: { enable_js_camera_doc_capture } }: SdkConfiguration,
  deviceHasCameraSupport?: boolean
): boolean => {
  const canUseJsCamera =
    (!isDesktop || isHybrid) && !!enable_js_camera_doc_capture

  return canUseJsCamera && deviceHasCameraSupport === true
}
