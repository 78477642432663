import { DocumentTypes } from '~types/steps'

const doubleSidedDocs: DocumentTypes[] = [
  'driving_licence',
  'national_identity_card',
  'residence_permit',
]

const isDoubleSidedDocument = (documentType: DocumentTypes | undefined) => {
  if (!documentType) {
    return false
  }

  return doubleSidedDocs.includes(documentType)
}

export { isDoubleSidedDocument }
