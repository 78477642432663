import { useCallback } from 'preact/compat'
import usePerformanceBenchmark from '~core/Benchmark/usePerformanceBenchmark'
import useDeviceCapabilities from '~core/DeviceCapabilities/useDeviceCapabilities'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { StepConfig } from '~types/steps'

const useMotion = () => {
  const { experimental_features, motion_capture } = useSdkConfigurationService()

  const motionSupported = motion_capture?.supported
  const motionABTestEnabled =
    experimental_features?.motion_experiment?.enabled ?? false
  const devicePassedBenchmark = usePerformanceBenchmark()
  const deviceCapabilities = useDeviceCapabilities()
  const deviceCanUseMotion =
    devicePassedBenchmark !== false &&
    deviceCapabilities?.wasm_support === true &&
    motionSupported === true

  const replaceFaceWithActiveVideoStep = useCallback(
    (steps: StepConfig[]) => {
      if (!deviceCanUseMotion || !motionABTestEnabled) {
        return steps
      }

      const faceIndex = steps.findIndex(({ type }) => type === 'face')
      const activeVideoIndex = steps.findIndex(
        ({ type }) => type === 'activeVideo'
      )

      if (faceIndex < 0 || activeVideoIndex >= 0) {
        return steps
      }

      const activeVideo: StepConfig = {
        type: 'activeVideo',
        //@ts-ignore
        original: steps[faceIndex],
      }

      return [
        ...steps.slice(0, faceIndex),
        activeVideo,
        ...steps.slice(faceIndex + 1),
      ]
    },
    [deviceCanUseMotion, motionABTestEnabled]
  )

  return { replaceFaceWithActiveVideoStep, deviceCanUseMotion }
}

export default useMotion
