import { v4 as uuidv4 } from 'uuid'
import { reduxStore } from 'components/ReduxAppWrapper'
import { createAnalyticsPayload } from '~core/Analytics/createAnalyticsPayload'
import { Logger } from '~core/Logger'
import pageVisibility from '~core/PageVisibility'
import { Queue, QueueItem } from '~core/util/Queue'
import { UrlsConfig } from '~types/commons'
import { sendAnalytics } from '~utils/onfidoApi'
import { BasePerformanceEvent, PerformanceEvent } from './types'
import type { OptionalPick } from '~types/util'

const logger = new Logger({ labels: ['performance_analytics'] })

let token: string | undefined
let urls: UrlsConfig
let enabled: boolean | undefined

class PerformanceAnalytics {
  private eventsMap: Map<string, PerformanceEvent> = new Map()
  private queue = new Queue<PerformanceEvent>({
    limit: 5,
    paused: true,
  })

  constructor() {
    this.queue.on('flush', this.sendToBackend)
    pageVisibility.addListener(this.pageVisiblityHandler)
    reduxStore.subscribe(this.reduxStoreHandler)

    window.addEventListener('beforeunload', this.clearEvents)
    window.addEventListener('close', this.clearEvents)
  }

  private pageVisiblityHandler = (visible: boolean) => {
    if (!visible) this.clearEvents()
  }

  private reduxStoreHandler = () => {
    const store = reduxStore.getState()

    token = store.globals.token
    urls = store.globals.urls
    enabled =
      store.globals.sdkConfiguration?.sdk_features
        ?.enable_performance_analytics ?? false

    if (token && urls && enabled) {
      this.queue.resume()
    }
  }

  // Public api
  public trackStart<T extends BasePerformanceEvent>(
    event: PerformanceEvent,
    ...[startProperties]: OptionalPick<T, 'start_properties'>
  ): void {
    if (!enabled) return
    this.eventsMap.set(event.uuid, {
      ...event,
      time: performance.now(),
      properties: {
        ...(event.properties || {}),
        ...(startProperties || {}),
      },
    })
  }

  public trackEnd<T extends BasePerformanceEvent>(
    endEvent: PerformanceEvent,
    ...[endProperties]: OptionalPick<T, 'end_properties'>
  ): void {
    const startEvent = this.eventsMap.get(endEvent.uuid)

    if (!enabled || !token) return
    if (!startEvent) {
      logger.error(`The start event was not found for ${endEvent.eventName}`)
      return
    }

    this.queue.push({
      ...startEvent,
      ...endEvent,
      properties: {
        duration: Math.round(performance.now() - startEvent.time!),
        ...(startEvent.properties || {}),
        ...(endProperties || {}),
      },
    })

    this.eventsMap.delete(endEvent.uuid)
  }

  public clearEvents = () => {
    logger.debug('Clearing events and sending to backend')
    this.eventsMap.clear()
    this.queue.flush(true)
  }

  private sendToBackend = (items: QueueItem<PerformanceEvent>[]) => {
    if (!token) return
    const events = items.map(({ timestamp, item }) =>
      createAnalyticsPayload({
        event: item.eventName,
        event_uuid: item.eventName === item.uuid ? undefined : item.uuid,
        event_time: new Date(timestamp).toISOString(),
        properties: item.properties,
      })
    )

    const url = `${urls.onfido_api_url}/v4/sdk/performance`
    sendAnalytics(url, JSON.stringify({ events }), token)
  }
}

export default new PerformanceAnalytics()

export const createSyncPerformanceEvent = <T extends BasePerformanceEvent>(
  eventName: T['eventName'],
  ...[properties]: T['properties'] extends never ? [] : [T['properties']]
): PerformanceEvent => ({
  uuid: eventName,
  eventName,
  properties: properties || {},
})

export const createAsyncPerformanceEvent = <T extends BasePerformanceEvent>(
  eventName: T['eventName'],
  ...[properties]: T['properties'] extends never ? [] : [T['properties']]
): PerformanceEvent => ({
  uuid: uuidv4(),
  eventName,
  properties: properties || {},
})
