import { h, FunctionComponent, ComponentType } from 'preact'
import { useEffect } from 'preact/hooks'
import { localised } from '~core/localisation'
import { connect } from 'react-redux'
import { FaceCapture, RootState } from '~types/redux'
import { buildCaptureStateKey } from '~utils/redux'
import { StepComponentProps } from '~types/routers'
import { WithTrackingProps } from '~types/hocs'
import { trackComponent } from 'Tracker'
import ScreenLayout from 'components/Theme/ScreenLayout'
import Spinner from 'components/Spinner'

type Props = StepComponentProps &
  WithTrackingProps & {
    capture?: FaceCapture
  }

const mapStateToProps = ({ captures }: RootState, props: Props): Props => ({
  ...props,
  capture: captures[buildCaptureStateKey({ method: 'face' })] as FaceCapture,
})

const WorkflowFallback: FunctionComponent<Props> = ({
  nextStep,
  completeStep,
  trackScreen,
}) => {
  useEffect(() => {
    completeStep([], 'upload_face_motion')
    trackScreen('upload_completed')
    nextStep()
  }, [])

  return (
    <ScreenLayout pageId={'WorkflowFallback'}>
      <Spinner />
    </ScreenLayout>
  )
}

// Note: Preact and Redux types don't play nice together, hence the type cast.
export default (connect(mapStateToProps)(
  trackComponent(
    localised(WorkflowFallback),
    'upload'
  ) as FunctionComponent<Props>
) as unknown) as ComponentType<StepComponentProps>
