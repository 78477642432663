import { v4 as uuidv4 } from 'uuid'
import { reduxStore } from 'components/ReduxAppWrapper'

import { cleanStepsForConfig } from 'Tracker/steps'
import { StepConfig } from '~types/steps'
import { trackedEnvironmentData } from '~utils'
import { DeviceTypes } from '~types/commons'

let analyticsSessionUuid: string
let steps: StepConfig[]
let client_uuid: string
let applicant_uuid: string
let anonymous_uuid: string
let is_trial: boolean

const listener = () => {
  const store = reduxStore.getState()
  analyticsSessionUuid = store.globals.analyticsSessionUuid!
  client_uuid = store.globals.clientUuid!
  applicant_uuid = store.globals.applicantUuid!
  anonymous_uuid = store.globals.anonymousUuid!
  is_trial = store.globals.isTrial!
  steps = cleanStepsForConfig(store.globals.stepsConfig)
}

reduxStore.subscribe(listener)

const environmentData = trackedEnvironmentData()

type AnalyticsPayloadProps<T> = {
  event: string
  event_time?: string
  event_uuid?: string
  properties: T
}

export type AnalyticsPayload<T> = {
  event: string
  event_uuid: string
  event_time: string

  source: 'sdk'

  applicant_uuid: string
  anonymous_uuid: string
  client_uuid: string
  session_uuid: string
  is_trial: boolean

  event_metadata: {
    domain: string
    os: string
    os_version: string
    browser: string
    browser_version: string
    device?: DeviceTypes
  }

  sdk_config: {
    expected_steps: string
    steps_config: StepConfig[]
  }

  source_metadata: {
    platform: string
    version: string
    sdk_environment: string
  }

  properties: T
}

export const generatePayloadTimestamp = () => new Date(Date.now()).toISOString()

export const createAnalyticsPayload = <T>(
  props: AnalyticsPayloadProps<T>
): AnalyticsPayload<T> => {
  return {
    event: props.event,
    event_uuid: props.event_uuid || uuidv4(),
    event_time: props.event_time || generatePayloadTimestamp(),

    source: 'sdk',

    applicant_uuid,
    anonymous_uuid,
    client_uuid,
    session_uuid: analyticsSessionUuid,
    is_trial,

    event_metadata: {
      domain: location.href,
      // os, os_version, browser, browser_version, device

      ...environmentData,
    },

    sdk_config: {
      expected_steps: steps?.map((step) => step['type']).join(),
      steps_config: steps,
    },

    source_metadata: {
      platform: process.env.SDK_SOURCE!,
      version: process.env.SDK_VERSION!,
      sdk_environment: process.env.NODE_ENV!,
    },

    properties: props.properties || ({} as T),
  }
}
