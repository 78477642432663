import { useEffect, useRef, useState } from 'preact/hooks'
import { sendEvent } from 'Tracker'
import { DeviceCapabilities, getDeviceCapabilities } from './deviceCapabilities'

const useDeviceCapabilities = (): DeviceCapabilities | undefined => {
  const [deviceCapabilities, setDeviceCapabilities] = useState<
    DeviceCapabilities | undefined
  >(undefined)
  const triggeredRef = useRef(false)

  useEffect(() => {
    if (!triggeredRef.current) {
      triggeredRef.current = true
      getDeviceCapabilities().then((capabilities) => {
        setDeviceCapabilities(capabilities)
        sendEvent(
          'device_capabilities',
          capabilities as Record<string, unknown>
        )
      })
    }
  })

  return deviceCapabilities
}

export default useDeviceCapabilities
