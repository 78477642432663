import { setNavigationDisabled } from 'components/ReduxAppWrapper/store/actions/globals'
import { useState } from 'preact/hooks'
import { useDispatch } from 'react-redux'
import { ActionCreatorsMapObject, Dispatch } from 'redux'
import { sendEvent, trackException } from 'Tracker'
import { WithLocalisedProps } from '~core/localisation/types'
import {
  ApiRawError,
  ChallengeData,
  DocumentImageResponse,
  FaceVideoResponse,
  ImageQualityValidationTypes,
  ImageQualityWarnings,
  ParsedError,
  UploadFileResponse,
  ValidationError,
  ValidationReasons,
} from '~types/api'
import {
  CaptureMethods,
  DocumentSides,
  ErrorNames,
  UrlsConfig,
} from '~types/commons'
import { EnterpriseFeatures } from '~types/enterprise'
import { TrackScreenCallback } from '~types/hocs'
import { CapturePayload, CombinedActions, FaceCapture } from '~types/redux'
import {
  CompleteStepCallback,
  CrossDeviceClientErrorCallback,
  ErrorProp,
  StepComponentBaseProps,
} from '~types/routers'
import { AnalyticsEventPropertiesWarnings } from '~types/tracker'
import {
  formatError,
  objectToFormData,
  UploadDocumentPayload,
  UploadVideoPayload,
} from '~utils/onfidoApi'

type ImageQualityValidationNames =
  | 'CUTOFF_DETECTED'
  | 'GLARE_DETECTED'
  | 'BLUR_DETECTED'

type CallbackTypes = 'selfie' | 'video' | 'document'
export type CallbackNames =
  | 'onSubmitSelfie'
  | 'onSubmitVideo'
  | 'onSubmitDocument'

const IMAGE_QUALITY_KEYS_MAP: Partial<
  Record<ImageQualityValidationTypes, ImageQualityValidationNames>
> = {
  detect_cutoff: 'CUTOFF_DETECTED', // error with the heighest priority
  detect_glare: 'GLARE_DETECTED',
  detect_blur: 'BLUR_DETECTED',
}
export const CALLBACK_TYPES: Record<CallbackTypes, CallbackNames> = {
  selfie: 'onSubmitSelfie',
  video: 'onSubmitVideo',
  document: 'onSubmitDocument',
}
const REQUEST_ERROR = 'REQUEST_ERROR'

export type OnApiErrorCallback = (error: ParsedError) => void

export type OnApiSuccessCallback = (
  apiResponse: DocumentImageResponse | FaceVideoResponse | UploadFileResponse
) => void

export type OnSubmitCallback = (
  data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
  callbackName: CallbackNames
) => Promise<void>

export type UploadCallback = (
  capture: CapturePayload,
  onApiSuccess: OnApiSuccessCallback,
  onApiError: OnApiErrorCallback,
  onSubmitCallback: OnSubmitCallback
) => void

export type SubmitUploadCallback = (
  data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
  callbackName: CallbackNames,
  url: string | undefined,
  token: string,
  onApiSuccess: OnApiSuccessCallback,
  onApiError: OnApiErrorCallback
) => void

export type ConfirmProps = {
  isFullScreen?: boolean
  capture: CapturePayload
  method: CaptureMethods
  country: string
  side: DocumentSides
  error: string
  upload: UploadCallback
  submitUpload: SubmitUploadCallback // this is for enterprise features.
  extraErrorAnalytics?: Record<string, unknown>
  extraWarningAnalytics?: Record<string, unknown>
} & StepComponentBaseProps &
  WithLocalisedProps

// a hook that uploads to onfido, ensuring error handling/reporting, user retries, and enterprises features. Does too many things and could be split up in smaller parts.
export const useConfirm = (
  actions: ActionCreatorsMapObject<CombinedActions>,
  capture: CapturePayload,
  method: CaptureMethods,
  token: string | undefined,
  urls: UrlsConfig,

  completeStep: CompleteStepCallback,
  nextStep: () => void,
  previousStep: () => void,
  resetSdkFocus: () => void,
  submitUpload: SubmitUploadCallback, // upload the capture to onfido after the enterprise feature
  trackScreen: TrackScreenCallback,
  triggerOnError: OnApiErrorCallback,
  upload: UploadCallback, // upload the capture to onfido

  crossDeviceClientError?: CrossDeviceClientErrorCallback,
  enterpriseFeatures?: EnterpriseFeatures,
  extraErrorAnalytics?: Record<string, unknown>,
  extraWarningAnalytics?: Record<string, unknown>,
  mobileFlow?: boolean
) => {
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [confirmError, setConfirmError] = useState<ErrorProp | undefined>(
    undefined
  )
  const dispatch = useDispatch<Dispatch<CombinedActions>>()

  const setError = (name: ErrorNames, errorMessage?: unknown) => {
    const error: ErrorProp = { name, type: 'error' }
    if (errorMessage) {
      error.properties = { error_message: errorMessage }
    }
    error.analyticsProperties = {
      ...error.analyticsProperties,
      ...extraErrorAnalytics,
    }

    setConfirmError(error)
    setUploadInProgress(false)
    dispatch(setNavigationDisabled(false))
    resetSdkFocus()
  }

  const setWarning = (
    name: ErrorNames,
    extraProps?: Record<string, unknown>,
    imageQualityWarnings?: ImageQualityWarnings
  ) => {
    const warningAnalytics: AnalyticsEventPropertiesWarnings = {}

    if (imageQualityWarnings) {
      // build the warnings for analytics purposes
      const validationReasons = Object.keys(
        imageQualityWarnings
      ) as ImageQualityValidationTypes[]

      validationReasons.forEach((reason) => {
        if (imageQualityWarnings[reason]?.valid) {
          return
        }
        switch (reason) {
          case 'detect_blur':
            warningAnalytics.blur = 'warning'
            break
          case 'detect_cutoff':
            warningAnalytics.cutoff = 'warning'
            break
          case 'detect_glare':
            warningAnalytics.glare = 'warning'
            break
          case 'detect_document':
            warningAnalytics.document = 'warning'
            break
        }
      })
    }

    setConfirmError({
      name,
      type: 'warning',
      analyticsProperties: { ...extraProps, ...{ warnings: warningAnalytics } },
    })

    setUploadInProgress(false)
    dispatch(setNavigationDisabled(false))
    resetSdkFocus()
  }

  /**
   * Transforms input into ErrorNames
   */
  const mapToErrorName = ([key, val]: [ValidationReasons, string[]]):
    | ErrorNames
    | undefined => {
    if (key === 'document_detection') return 'DOCUMENT_DETECTION'
    // on corrupted PDF or other unsupported file types
    if (key === 'file') return 'INVALID_TYPE'
    // hit on PDF/invalid file type submission for face detection
    if (key === 'attachment' || key === 'attachment_content_type')
      return 'UNSUPPORTED_FILE'
    if (key === 'face_detection') {
      return val[0].indexOf('Multiple faces') === -1
        ? 'NO_FACE_ERROR'
        : 'MULTIPLE_FACES_ERROR'
    }
  }

  /**
   * Returns the first image quality error in terms of priority, or undefined if none is found
   */
  const returnFirstImageQualityErrorFound = (
    errorField: ValidationError['fields']
  ) => {
    const imageQualityKeys = Object.keys(IMAGE_QUALITY_KEYS_MAP) as Array<
      keyof typeof IMAGE_QUALITY_KEYS_MAP
    >
    for (const errorKey of imageQualityKeys) {
      if (Object.keys(errorField).includes(errorKey))
        return IMAGE_QUALITY_KEYS_MAP[errorKey]
    }
  }

  /**
   * Transforms a backend response that can contain multiple errors into a single error, defined by priority.
   */
  const onfidoErrorReduce = ({ fields }: ValidationError) => {
    const imageQualityError = returnFirstImageQualityErrorFound(fields)
    const entriesOfFields = Object.entries(fields) as Array<
      [ValidationReasons, string[]]
    >

    const [first] = entriesOfFields.map(mapToErrorName)
    return first || imageQualityError
  }

  const onApiError = (error: ParsedError) => {
    const status = error.status || 0
    const response = error.response || {}

    if (mobileFlow && status === 401) {
      triggerOnError({ status, response })
      if (crossDeviceClientError) {
        return crossDeviceClientError()
      }
      return
    }
    if (status === 422) {
      if (response?.error) {
        const validationError = response.error as ValidationError
        const errorKey = onfidoErrorReduce(validationError)
        setError(errorKey as ErrorNames)
        return
      }
      setError(REQUEST_ERROR)
      return
    }
    if (status === 403 && response.error?.type === 'geoblocked_request') {
      setError(
        'GEOBLOCKED_ERROR',
        'generic.errors.geoblocked_error.instruction'
      )
      return
    }

    triggerOnError({ status, response })
    trackException(`${status} - ${response}`)
    setError(REQUEST_ERROR, response?.error?.message)
  }

  const onVideoPreviewError = () => setError('VIDEO_ERROR' as ErrorNames)

  /**
   * Returns the first image quality warning found in the warnings, if no truthy 'valid' property is found.
   * The order depends on the IMAGE_QUALITY_KEYS_MAP map.
   */
  const returnFirstImageQualityWarning = (
    warnings: ImageQualityWarnings
  ): ImageQualityValidationNames | undefined => {
    const imageQualityKeys = Object.keys(IMAGE_QUALITY_KEYS_MAP) as Array<
      keyof typeof IMAGE_QUALITY_KEYS_MAP
    >

    const warningsKey = Object.keys(warnings) as Array<keyof typeof warnings>

    for (const warnKey of imageQualityKeys) {
      if (warningsKey.includes(warnKey) && !warnings[warnKey]?.valid)
        return IMAGE_QUALITY_KEYS_MAP[warnKey]
    }
  }

  const onImageQualityWarning = (
    apiResponse: DocumentImageResponse
  ): ImageQualityValidationNames | undefined => {
    const { sdk_warnings: warnings } = apiResponse
    if (!warnings) {
      return
    }
    return returnFirstImageQualityWarning(warnings)
  }

  const onApiSuccess = (
    apiResponse: DocumentImageResponse | FaceVideoResponse | UploadFileResponse
  ) => {
    actions.setCaptureMetadata({ capture, apiResponse })
    dispatch(setNavigationDisabled(false))

    const documentImageResponse = apiResponse as DocumentImageResponse
    const imageQualityWarning = onImageQualityWarning(documentImageResponse)

    if (!imageQualityWarning) {
      completeStep([{ id: apiResponse.id }])
      nextStep()
    } else {
      setWarning(
        imageQualityWarning,
        extraWarningAnalytics,
        documentImageResponse.sdk_warnings
      )
      completeStep([{ id: apiResponse.id }])
    }
  }

  const onSubmitCallback = async (
    data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
    callbackName: CallbackNames
  ) => {
    if (!token) {
      throw new Error('token not provided')
    }

    if (!enterpriseFeatures) {
      throw new Error('no enterprise features')
    }

    const url = urls.onfido_api_url
    const formDataPayload = prepareCallbackPayload(data, callbackName)

    const startTime = performance.now()

    sendEvent(`Triggering ${callbackName} callback`)
    sendEvent('Starting upload', { method })

    try {
      const enterpriseFeaturesCallback = enterpriseFeatures[callbackName]

      if (!enterpriseFeaturesCallback) {
        throw new Error('no enterprise features')
      }

      const {
        continueWithOnfidoSubmission,
        onfidoSuccessResponse,
      } = await enterpriseFeaturesCallback(formDataPayload)

      if (onfidoSuccessResponse) {
        sendEvent(`Success response from ${callbackName}`)
        sendEvent('Completed upload', {
          method,
          duration: Math.round(performance.now() - startTime),
        })
        onApiSuccess(onfidoSuccessResponse)
      } else if (continueWithOnfidoSubmission) {
        submitUpload(data, callbackName, url, token, onApiSuccess, onApiError)
      } else {
        console.error(`Invalid return statement from ${callbackName}`)
      }
    } catch (errorResponse: unknown) {
      sendEvent(`Error response from ${callbackName}`)
      formatError(errorResponse as ApiRawError, onApiError)
    }
  }

  const prepareCallbackPayload = (
    data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
    callbackName: CallbackNames
  ) => {
    let payload
    if (callbackName === CALLBACK_TYPES.selfie) {
      const { blob, filename, snapshot } = data as FaceCapture
      payload = {
        file: filename ? { blob, filename } : blob,
        snapshot,
      }
    } else if (callbackName === CALLBACK_TYPES.video) {
      const { blob, language, challengeData } = data as UploadVideoPayload
      const {
        challenges: challenge,
        id: challenge_id,
        switchSeconds: challenge_switch_at,
      } = challengeData as ChallengeData
      payload = {
        file: blob,
        challenge: JSON.stringify(challenge),
        challenge_id,
        challenge_switch_at,
        languages: JSON.stringify([{ source: 'sdk', language_code: language }]),
      }
    } else if (callbackName === CALLBACK_TYPES.document) {
      const { file, side, type, validations } = data as UploadDocumentPayload
      payload = {
        file,
        side,
        type,
        sdk_validations: JSON.stringify(validations),
      }
    }
    return objectToFormData({
      sdk_metadata: JSON.stringify(data.sdkMetadata),
      sdk_source: process.env.SDK_SOURCE,
      sdk_version: process.env.SDK_VERSION,
      ...payload,
    })
  }

  const uploadCaptureToOnfido = () => {
    setUploadInProgress(true)
    dispatch(setNavigationDisabled(true))

    upload(capture, onApiSuccess, onApiError, onSubmitCallback)
  }

  const onRetake = () => {
    trackScreen('retake_button_clicked', {
      count_attempt: capture.sdkMetadata.take_number,
    })

    const imageQualitiesKeys = Object.keys(IMAGE_QUALITY_KEYS_MAP) as Array<
      keyof typeof IMAGE_QUALITY_KEYS_MAP
    >
    // Retake on image quality error, increase image quality retries
    const isImageQualityError = imageQualitiesKeys.find(
      (key) => IMAGE_QUALITY_KEYS_MAP[key] === confirmError?.name
    )

    if (isImageQualityError && confirmError?.type === 'error') {
      actions.retryForImageQuality()
    }

    previousStep()
  }

  const onConfirm = () => {
    trackScreen('upload_button_clicked', {
      count_attempt: capture.sdkMetadata.take_number,
    })

    if (confirmError?.type === 'warning') {
      actions.resetImageQualityRetries()
      nextStep()
    } else {
      uploadCaptureToOnfido()
    }
  }

  return {
    uploadInProgress,
    confirmError,
    onConfirm,
    onRetake,
    onVideoPreviewError,
  }
}
